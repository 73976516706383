import React, {Component} from 'react';
import {getCompanyDepartment, getCompanyLocation, launchSurvey} from "../../redux/actions";
import {CustomMenuItem, FieldTitle, FormContainer, ImageContainer, ImageContentContainer, ImageInput,
  Layout, PageTitle, RemovePhotoButton, SaveButton, StyledInput, InviteAmigo, CustomCheckbox,
  TitleContainer, InputContainer, StyledDateTime, DateIconContainer, ButtonContainer, NameContainer, CheckMark} from "../CreateEvent/styles";
import {StartDateContainerV2, SurveyDepartments, InviteContainerV2, SurveyLocations, ScrollableContainerV2,
  LocationContainer, } from "./styles";
import RadioButton from "../CustomRadioButton";
import {RadioButtonSurveyConatiner} from "../CreateSurveys/styles"
import SurveyQuestions  from '../SurveyQuestions'
import { ActivityDropdown, ActivityTypeContainer } from '../CreateChallengeForm/styles';
import Waiting from "../Waiting";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import { /* challengePointItems, */ surveyOptionTypes } from '../../../mockData';
import {checkImage, convertDateInTimezone, getOrientation, getPermissionStatus, resetOrientation} from "../../utils/methods";
import {imageErrorMessage, ImageUrl} from "../../utils/constants";
import {toast} from "react-toastify";
import _ from 'lodash';
import moment from "moment/moment";
import LazyImage from '../common/LazyImage/LazyImage';
import CommonTextArea from '../common/CommonTextArea/CommonTextArea';


class LaunchSurveys extends Component{
  constructor(props) {
    super(props);
    let pointsArray = this.challengePointItems;
    let indexExists = this.challengePointItems.findIndex((data) => data === 'No points');
    indexExists === -1 && pointsArray.unshift("No points");
    this.state = {
      title: '',
      imgSrc: '',
      imageName: '',
      imageUpdated: 0,
      points: 'Tier 1: 25 points',
      description:'',
      surveyPoints: pointsArray,
      error: [],
      hasSections: 1,
      surveyDetails: {},
      companyId: props.companyInfo['id'],
      surveyType: 'Culture',
      openDateTimePicker:'',
      showDateTimeInput: '',
      date: moment().add(1, 'days').format('MM/DD/YYYY'),
      endDate: moment().add(1,'days').format('MM/DD/YYYY'),
      surveyDepartment: [],
      surveyLocation: [],
      surveyFor: 0,
      prevSectionsData: [
        {
          "title": '',
          "data": [
            {
              "question": '',
              "options": surveyOptionTypes[0].options,
              "question_type": surveyOptionTypes[0].questionType
            }
          ]
        }
      ],
      prevQuestionsData: [
        {
          "question" : '',
          "options": surveyOptionTypes[0].options,
          "question_type": surveyOptionTypes[0].questionType
        }
      ],
      questions: [],
      buttonDisable: true,
      sections: [
        {
          "title": '',
          "data": [
            {
              "question" : '',
              "options": surveyOptionTypes[0].options,
              "question_type": surveyOptionTypes[0].questionType
            }
          ]
        }
      ]
    };
  }

  challengePointItems= [
    "Tier 1: 25 points",
    "Tier 2: 100 points",
    "Tier 3: 250 points",
    "Tier 4: 500 points"
  ]

  componentDidMount() {
    const { surveyDetails, history, isCustomSurvey } = this.props;
    const{getCompanyLocation, getCompanyDepartment } = this.props;
    getCompanyLocation(this.state.companyId,false);
    getCompanyDepartment(this.state.companyId,false);
    document.addEventListener('mousedown', this.handleClick);

    if (!isCustomSurvey) {
      if (!surveyDetails || _.isEmpty(surveyDetails)) {
        history.push('/company/surveys/show-all');
      } else {
        this.setState({
          surveyId: surveyDetails.id,
          imgSrc: surveyDetails.image,
          title: surveyDetails.survey_name,
          description: surveyDetails.description,
          imageName: surveyDetails.image,
          hasSections: surveyDetails.has_sections,
          points: surveyDetails.survey_point === 0 ? 'No points' :
            surveyDetails.survey_point === 25 ? "Tier 1: 25 points" :
              surveyDetails.survey_point === 100 ? "Tier 2: 100 points" :
                surveyDetails.survey_point === 250 ? "Tier 3: 250 points" :
                  surveyDetails.survey_point === 500 ? "Tier 4: 500 points" : "Tier 1: 25 points",
          sections: surveyDetails.has_sections === 1 ? surveyDetails.data : [],
          questions: surveyDetails.has_sections !== 1 ? surveyDetails.data : [],
        }, () => {
          this.buttonDisableFunc()
        });
      }
    }

  }

  componentDidUpdate(prevProps) {
    const { departmentDetails, locationDetails } = this.props;
    if(prevProps.departmentDetails != departmentDetails || prevProps.locationDetails != locationDetails) {
      let departmentStatus = departmentDetails && departmentDetails.length;
      let locationStatus = locationDetails && locationDetails.length;
      if (!(departmentStatus && locationStatus) && (departmentStatus || locationStatus)) {

        this.setState({
          surveyFor: (!departmentStatus) ? 0 : 1
        })
      }
    }

  }

  updateSurveyQuestions = (questions) => {
    this.setState({
      questions: [...questions]
    }, () => this.buttonDisableFunc());
  };
  updateSurveySections = (sections) => {
    this.setState({
      sections: [...sections]
    }, () => this.buttonDisableFunc());
  };
  toggleSurveyRadioButton = (obj) => {
    this.setState(obj, () => this.buttonDisableFunc());
  };
  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onSelectPoints = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  changeDate = (e, stateName) => {
    const { showDateTimeInput } = this.state;
    if(showDateTimeInput === 'date') {
      this.setState({
        date: moment(e._d).format('MM/DD/YYYY'),
        endDate: moment(e._d).format('MM/DD/YYYY'),
        openDateTimePicker: '',
      });
    } else if(stateName.includes('ate')) {
      this.setState({
        openDateTimePicker: '',
        [stateName]: moment(e._d).format('MM/DD/YYYY'),
      });
    }
  };

  showDatePicker = (value) => {
    if(this.state.openDateTimePicker === value) {
      this.setState({
        openDateTimePicker: '',
        date: moment().add(1, 'days').format('MM/DD/YYYY'),
        endDate: moment().add(1,'days').format('MM/DD/YYYY'),
      });
    } else {
      this.setState({
        openDateTimePicker: value
      });
    }
  };
  setDatePickerWrapper = (node) =>  {
    this.datePickerWrapper = node;
  };

  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if(e.target.files[0]) {
      if(checkImage(fileArr)) {
        if((file?.size/1000000) <= 20){
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document.getElementById('survey-upload-file').value.split("\\");
          reader.onloadend = () => {
            this.setState({
              imgSrc: reader.result,
              imageName: array[array.length - 1],
              imageUpdated: 1
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  imgSrc: baseImage
                });
              });
            });
          };
        } else {
          toast.error('Please select image file less than 20MB');
          document.getElementById('survey-upload-file').value = '';
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById('survey-upload-file').value = '';
      }
    }
  };

  saveSurvey = (e) => {
    e.preventDefault();
    const{history, launchSurvey, departmentDetails, locationDetails, isCustomSurvey} = this.props;
    const {title, imgSrc, points, description, hasSections, date, endDate, surveyFor, surveyLocation, surveyDepartment, sections, questions, companyId, imageUpdated, surveyId} = this.state;
    let obj = {
      'title': title,
      'image': imgSrc,
      'company_id': companyId,
      'start_date': moment(date).format('YYYY-MM-DD'),
      'end_date': moment(endDate).format('YYYY-MM-DD'),
      'city_states': [],
      'departments': [],
      'description': description,
      'survey_point': points === 'No points' ? 0 : points === "Tier 1: 25 points" ? 25 : points === "Tier 2: 100 points" ? 100 : points === "Tier 3: 250 points" ? 250 : 500,
      'has_sections': hasSections,
      'imageUpdated': imageUpdated,
      'launch_survey_for': surveyFor,
      'data': hasSections !== 0 ? sections : questions,
      'survey_id': isCustomSurvey ? null : surveyId
    };
    if ((departmentDetails && departmentDetails.length) || (locationDetails && locationDetails.length)) {
      // (surveyFor === 1) ? obj['departments'] = surveyDepartment : obj['city_states'] = surveyLocation;
      if(surveyFor === 1){
        obj['departments'] = surveyDepartment
      }
      else{
        obj['city_states'] = surveyLocation 
      }
    }
    launchSurvey(obj, history);
  };

  buttonDisableFunc = () => {
    const {hasSections} = this.state;
    if (hasSections !== 0) {
      let dupSections = [...this.state.sections];
      let emptyOption = dupSections.findIndex((list) =>
        (!_.isUndefined(list.title) && list.title.trim() === '') || list.data.length === 0 || ((list.data.findIndex((question) => question.question.trim() === '' || (question.question_type === 4 ? question.options.length !== 0 : question.options.length < 2 || (question.options.findIndex((option) => option.trim() === '') !== -1)))) !== -1)
      );

      if (emptyOption == -1 && dupSections.length >= 2) {
        this.setState({buttonDisable: false});
      }
      else {
        this.setState({buttonDisable: true});
      }
    } else {
      let dupQuestions = [...this.state.questions];
      let emptyOption = dupQuestions.findIndex((list) =>
        (!_.isUndefined(list.question) && list.question.trim() === '') || ( list.question_type === 4 ? list.options.length !== 0 : list.options.length < 2 || (list.options.findIndex((option) => option.trim() === '') !== -1))
      );

      if (emptyOption == -1 && dupQuestions.length >= 2) {
        this.setState({buttonDisable: false});
      }
      else {
        this.setState({buttonDisable: true});
      }
    }
  };

  removePhoto = () => {
    document.getElementById('survey-upload-file').value = '';
    this.setState({
      imgSrc: '',
      imageName: '',
    });
  };

  selectAll = (e, details, name) => {
    e.preventDefault();
    const { surveyLocation, surveyDepartment} = this.state;
    if(name == 'surveyLocation') {
      if(surveyLocation.length === details.length) {
        this.setState({
          surveyLocation: []
        })
      }
      else {
        let arr = [];
        for(let i=0; i< details.length; i++) {
          arr.push(details[i].id);
        }
        this.setState({
          surveyLocation: arr
        })
      }
    } else {
      if(surveyDepartment.length === details.length) {
        this.setState({
          surveyDepartment: []
        })
      }
      else {
        let arr = [];
        for(let i=0; i< details.length; i++) {
          arr.push(details[i].id);
        }
        this.setState({
          surveyDepartment: arr
        })
      }
    }
  };

  onChangeLocation = (id, name) => {
    const { surveyLocation, surveyDepartment} = this.state;
    if(name == 'surveyLocation') {
      if (surveyLocation.includes(id)) {
        let index = surveyLocation.findIndex((item) => item === id);
        if (index > -1) {
          surveyLocation.splice(index, 1);
        }
      } else {
        surveyLocation.push(id);
      }
      this.setState({
        surveyLocation: surveyLocation
      })
    } else{
      if (surveyDepartment.includes(id)) {
        let index = surveyDepartment.findIndex((item) => item === id);
        if (index > -1) {
          surveyDepartment.splice(index, 1);
        }
      } else {
        surveyDepartment.push(id);
      }
      this.setState({
        surveyDepartment: surveyDepartment
      })
    }
  };

  renderDepartments = (departmentDetails, surveyDepartment) => (
    <TitleContainer>
      <SurveyDepartments>
        <FieldTitle>Company Departments:</FieldTitle>
        <ButtonContainer>
          {departmentDetails && departmentDetails.length > 0 ?
            <SaveButton color="#159fc9" onClick={(e) => this.selectAll(e, departmentDetails, 'surveyDepartment')}>
              <i className="fas fa-check"/>
              {
                surveyDepartment.length === departmentDetails.length ? 'deselect all departments' : 'select all departments'
              }
            </SaveButton> : null
          }
        </ButtonContainer>
        <InviteContainerV2>
          <ScrollableContainerV2>
            <LocationContainer>
              {
                departmentDetails && departmentDetails.length > 0 ? departmentDetails.map((list, index) => (

                  <InviteAmigo key={index}>
                    <CustomCheckbox>
                      <input
                        type="checkbox"
                        checked={surveyDepartment.includes(list.id)}
                        onChange={() => this.onChangeLocation(list.id, 'surveyDepartment')}
                      />
                      <CheckMark checked={surveyDepartment.includes(list.id)} />
                    </CustomCheckbox>
                    <NameContainer onClick={() => this.onChangeLocation(list.id, 'surveyDepartment')}>
                      <span>{list.department}</span>
                    </NameContainer>
                  </InviteAmigo>
                )) : null
              }
            </LocationContainer>
          </ScrollableContainerV2>
        </InviteContainerV2>
      </SurveyDepartments>
    </TitleContainer>
  );

  renderLocations = (locationDetails, surveyLocation) => (
    <TitleContainer>
      <SurveyLocations>
        <FieldTitle>Company Locations:</FieldTitle>
        <ButtonContainer>
          {locationDetails && locationDetails.length > 0 ?
            <SaveButton color="#159fc9" onClick={(e) => this.selectAll(e, locationDetails, 'surveyLocation')}>
              <i className="fas fa-check"/>
              {
                surveyLocation.length === locationDetails.length ? 'deselect all locations' : 'select all locations'
              }
            </SaveButton> : null
          }
        </ButtonContainer>
        <InviteContainerV2>
          <ScrollableContainerV2>
            <LocationContainer>
              {
                locationDetails && locationDetails.length > 0 ? locationDetails.map((loc, index) => (
                  <InviteAmigo key={index}>
                    <CustomCheckbox>
                      <input
                        type="checkbox"
                        checked={surveyLocation.includes(loc.id)}
                        onChange={() => this.onChangeLocation(loc.id, 'surveyLocation')}
                      />
                      <CheckMark checked={surveyLocation.includes(loc.id)} />
                    </CustomCheckbox>
                    <NameContainer onClick={() => this.onChangeLocation(loc.id, 'surveyLocation')}>
                      <span>{loc.location}</span>
                    </NameContainer>
                  </InviteAmigo>
                )) : null
              }
            </LocationContainer>
          </ScrollableContainerV2>
        </InviteContainerV2>
      </SurveyLocations>
    </TitleContainer>
  );

  toggleSurveyForRadioButton = (e) => {
    this.setState({
      surveyFor: e.target.value
    });
  };
  onSelectSurveyType = (questionIndex, type, options, sectionIndex) => {
    const {sections, questions, hasSections} = this.state;
    if(hasSections !== 0){
      sections[sectionIndex].data[questionIndex]['options'] = (type === 2) ? [''] : options;
      sections[sectionIndex].data[questionIndex]['question_type'] = type;
      this.setState({sections: sections}, () => this.buttonDisableFunc());
    } else{
      questions[questionIndex]['options'] = (type === 2) ? [''] : options;
      questions[questionIndex]['question_type'] = type;
      this.setState({questions: questions}, () => this.buttonDisableFunc());
    }
  };
  render() {
    const {title, imageName, points, surveyPoints, description, surveyDepartment, surveyLocation, endDate, date, showDateTimeInput, imgSrc, openDateTimePicker, imageUpdated, hasSections, sections, questions, prevQuestionsData, prevSectionsData, buttonDisable, surveyFor} = this.state;
    const {isLoading, surveyDetails, departmentDetails, locationDetails, isCustomSurvey, userPermissions} = this.props;
    const valid = (currentDate) => showDateTimeInput === 'date' ? currentDate.isAfter(moment()) : currentDate.isAfter(convertDateInTimezone(date).subtract(1, 'days'));
    const locationStatus = locationDetails && locationDetails.length;
    const departmentStatus = departmentDetails && departmentDetails.length;

    if (isLoading || (!isCustomSurvey && (!surveyDetails || _.isEmpty(surveyDetails)))) {
      return <Waiting />;
    }

    let isDisabled = false;
    if(description === '' || title === '' || imgSrc === '' || imageName === ''){
      isDisabled = true;
    }
    const showLocations = getPermissionStatus("Show locations in survey", userPermissions);
    const showDeparments = getPermissionStatus("Show departments in survey", userPermissions);
    if ((locationStatus && showLocations) || (showDeparments && departmentStatus)) {
      if (surveyFor === 1 && !surveyDepartment.length){
        isDisabled = true;
      } else if(surveyFor !==1 && !surveyLocation.length) {
        isDisabled = true;
      }
    }
    if(endDate < date){
      isDisabled = true;
    }
    return (
      <Layout>
        <PageTitle>Launch Survey</PageTitle>
        <div style={{float: 'left', width: '100%'}}>
          <FormContainer onSubmit={this.saveSurvey}>
            <TitleContainer>
              <FieldTitle>Survey Title:</FieldTitle>
              <StyledInput
                placeholder="Type title here..."
                name="title"
                onChange={this.onChangeInput}
                value={title}
                maxLength="100"
                type="text"
              />
            </TitleContainer>
            <ImageContainer>
              <FieldTitle>Survey Picture:</FieldTitle>
              <div>
                { imageName &&
                    <div>
                      <img src={imageUpdated ? imgSrc : `${ImageUrl}/${imgSrc}`} />
                      <ImageContentContainer>
                        <div>{imageName}</div>
                        <RemovePhotoButton color="#aaaaaa" type="button" onClick={this.removePhoto}>Remove photo</RemovePhotoButton>
                      </ImageContentContainer>
                    </div>
                }
                <ImageInput htmlFor="survey-upload-file" showAtBottom={imageName !== ''}>
                  {imageName ? 'browse new' : 'browse'}
                  <input
                    id="survey-upload-file"
                    type="file"
                    name="user"
                    accept=".jpeg, .png, .jpg"
                    multiple={false}
                    onChange={(e) => this.onChange(e)}
                    onClick={(e) => e.target.files[0] && this.onChange(e)}
                  />
                </ImageInput>
              </div>
            </ImageContainer>
            <TitleContainer>
              <ActivityTypeContainer>
                <FieldTitle>Point Value:</FieldTitle>
                <ActivityDropdown
                  title={points}
                  id="dropdown-basic"
                >
                  {
                    surveyPoints.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onSelect={() => this.onSelectPoints('points', item)}
                        active={points === item}
                      >
                        {item}
                      </CustomMenuItem>
                    ))
                  }
                </ActivityDropdown>
              </ActivityTypeContainer>
            </TitleContainer>
            {<TitleContainer>
              <FieldTitle>Time to complete:</FieldTitle>
              <div>
                <StartDateContainerV2>
                  <div>From</div>
                  <InputContainer>
                    <div ref={(node) => openDateTimePicker === 'date' && this.setDatePickerWrapper(node)}>
                      <StyledDateTime
                        open={openDateTimePicker === 'date'}
                        inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true}}
                        dateFormat="MM/DD/YYYY"
                        closeOnSelect={true}
                        closeOnTab={true}
                        timeFormat={false}
                        value={date}
                        onChange={(e) => this.changeDate(e, 'date')}
                        isValidDate={valid}
                      />
                      <DateIconContainer onClick={() => this.showDatePicker('date')}>
                        <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                      </DateIconContainer>
                    </div>
                  </InputContainer>
                </StartDateContainerV2>
                <StartDateContainerV2>
                  <div>To</div>
                  <InputContainer>
                    <div ref={(node) => openDateTimePicker === 'endDate' && this.setDatePickerWrapper(node)}>
                      <StyledDateTime
                        open={openDateTimePicker === 'endDate'}
                        inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true}}
                        dateFormat="MM/DD/YYYY"
                        closeOnSelect={true}
                        closeOnTab={true}
                        timeFormat={false}
                        value={endDate}
                        onChange={(e) => this.changeDate(e, 'endDate')}
                        isValidDate={valid}
                      />
                      <DateIconContainer onClick={() => this.showDatePicker('endDate')} >
                        <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                      </DateIconContainer>
                    </div>
                  </InputContainer>
                </StartDateContainerV2>
              </div>
            </TitleContainer>
            }
            { (departmentDetails && departmentDetails.length > 0) && (locationDetails && locationDetails.length > 0) && (showLocations && showDeparments)?
              <TitleContainer>
                <FieldTitle>Launch Survey For:</FieldTitle>
                <RadioButtonSurveyConatiner>
                  <RadioButton id="1" handler={this.toggleSurveyForRadioButton} value={1} isChecked={surveyFor === 1} label={'Departments'} challengeLeaderBoard={true}/>
                  <RadioButton id="2" handler={this.toggleSurveyForRadioButton} value={0} isChecked={surveyFor === 0} label={'Locations'} challengeLeaderBoard={true}/>
                </RadioButtonSurveyConatiner>
              </TitleContainer>
              : null
            }
            {surveyFor === 1 ?
              showDeparments && departmentDetails && departmentDetails.length > 0 ?
                this.renderDepartments(departmentDetails, surveyDepartment)
                : null
              : showLocations && locationDetails && locationDetails.length > 0 ?
                this.renderLocations(locationDetails, surveyLocation)
                : null
            }
            <TitleContainer>
              <FieldTitle>Survey Description:</FieldTitle>
              <CommonTextArea
                placeholder="Write your description here..."
                rows="8"
                name="description"
                value={description}
                onChange={this.onChangeInput}
                data-gramm_editor="false"
                type="text"
              />
            </TitleContainer>
            <SurveyQuestions
              questions={questions}
              sections={sections}
              prevQuestionsData={prevQuestionsData}
              prevSectionsData={prevSectionsData}
              hasSections={hasSections}
              toggleSurveyRadioButton={this.toggleSurveyRadioButton}
              updateSurveyQuestions={this.updateSurveyQuestions}
              updateSurveySections={this.updateSurveySections}
              onSelectSurveyType={this.onSelectSurveyType}
            />
            <ImageContainer>
              <SaveButton type="submit" disabled={isDisabled || buttonDisable} addMargin>Launch Survey</SaveButton>
            </ImageContainer>
          </FormContainer>
        </div>
      </Layout>

    );
  }
}
LaunchSurveys.propTypes = {
  history: PropTypes.object,
  launchSurvey: PropTypes.func,
  isLoading: PropTypes.bool,
  surveyDetails: PropTypes.object,
  companyInfo: PropTypes.object,
  isCustomSurvey: PropTypes.bool,
  locationDetails: PropTypes.array,
  departmentDetails: PropTypes.array,
  getCompanyLocation: PropTypes.func,
  getCompanyDepartment: PropTypes.func,
  userPermissions: PropTypes.array
};
const mapStateToProps = (state) => ({
  isLoading: state.surveys.isLoading,
  surveyDetails: state.surveys.surveyDetails,
  locationDetails: state.register.locationDetails,
  departmentDetails: state.register.departmentDetails,
  userPermissions: state.profileData.userPermissions
});
const mapDispatchToProps = (dispatch) => ({
  getCompanyLocation: (id, bool) => dispatch(getCompanyLocation(id, bool)),
  getCompanyDepartment: (id, bool) => dispatch(getCompanyDepartment(id, bool)),
  launchSurvey: (data, history) => dispatch(launchSurvey(data, history))
});
export default connect(mapStateToProps, mapDispatchToProps)(LaunchSurveys);